import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { useState } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "./App.css";
import AppContext from "./AppContext";
import AuthRoute from "./Components/AuthRoute";
import Navbar from "./Components/Navbar";
import { Applications } from "./Pages/Applications";
import Dashboard from "./Pages/Dashboard";
import Home from "./Pages/Home";
import Login from "./Pages/Login";
import Logout from "./Pages/Logout";
import NotFound from "./Pages/NotFound";
import Portfolio from "./Pages/Portfolio";
import Register from "./Pages/Register";
import { getToken } from "./Services/AuthApi";

function App() {
    const [authToken, setAuthToken] = useState<string | null>(getToken());

    const router = createBrowserRouter([
        { path: "/", element: <Home /> },
        { path: "*", element: <NotFound /> },
        { path: "/login", element: <Login /> },
        { path: "/register", element: <Register /> },
        { path: "/logout", element: <Logout /> },
        { path: "/dashboard", element: <AuthRoute element={<Dashboard />} /> },
        { path: "/portfolio", element: <AuthRoute element={<Portfolio />} /> },
        {
            path: "/applications",
            element: <AuthRoute element={<Applications />} />,
        },
    ]);

    const theme = createTheme({
        palette: {
            mode: "dark",
            primary: {
                main: "#5e35b1",
            },
            secondary: {
                main: "#ede7f6",
            },
        },
    });

    return (
        <AppContext.Provider value={{ authToken, setAuthToken }}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <div className="App">
                    <Navbar />
                    <div className="Content">
                        <RouterProvider router={router} />
                    </div>
                </div>
            </ThemeProvider>
        </AppContext.Provider>
    );
}

export default App;
