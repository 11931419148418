import { useContext } from "react";
import AppContext from "../../AppContext";
import { clearToken } from "../../Services/AuthApi";

const Logout = (): JSX.Element => {
    const { setAuthToken } = useContext(AppContext);

    setAuthToken(null);
    clearToken();
    window.location.replace("/");

    return <></>;
};

export default Logout;
