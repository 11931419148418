import { Button, Container, FormControl, TextField } from "@mui/material";
import AxiosClient from "../../Services/AxiosClient";
import { useRef, useState } from "react";
import { Project } from "./Project";

type ProjectForm = {
    name: string;
    description: string;
    image: File | null;
    langage: string;
    framework: string;
};

type ImageType = {
    preview: string;
    image: File;
    imageName: string;
};

type FormPortfolioType = {
    onNeedRefresh: () => void;
};

const FormPortfolio = ({ onNeedRefresh }: FormPortfolioType) => {
    const [projectForm, setProjectForm] = useState<ProjectForm>({
        description: "",
        framework: "",
        image: null,
        langage: "",
        name: "",
    });
    const [selectedImage, setSelectedImage] = useState<ImageType>();
    const imageInputRef = useRef<HTMLInputElement>(null);
    const [errors, setErrors] = useState<Set<keyof Project>>(
        () => new Set<keyof Project>()
    );

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setProjectForm((projectForm) => {
            return { ...projectForm, [e.target.name]: e.target.value };
        });
    };

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        AxiosClient.post(
            "https://api.otchi.ovh/portfolio/project",
            projectForm,
            { headers: { "Content-Type": "multipart/form-data" } }
        )
            .then(() => {
                onNeedRefresh();
            })
            .catch((err) => {
                const messages = err.response.data.message as string[];
                const keysError = new Set<keyof Project>();
                for (const message of messages) {
                    const key = message.split(" ")[0] as keyof Project;
                    keysError.add(key);
                }
                setErrors(keysError);
            });
    };

    const onImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const selectedImage = e.target.files[0];

            let reader = new FileReader();

            reader.readAsDataURL(selectedImage);

            const preview = URL.createObjectURL(selectedImage);

            setSelectedImage({
                image: selectedImage,
                preview,
                imageName: e.target.value.split("\\")[2],
            });
            setProjectForm((projectForm) => {
                return { ...projectForm, image: selectedImage };
            });
        }
    };

    return (
        <Container
            component={"form"}
            onSubmit={onSubmit}
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                maxHeight: "80vh",
            }}
        >
            <FormControl sx={{ width: "75%", overflowX: "auto" }}>
                <TextField
                    sx={{ my: "1rem" }}
                    label="Name"
                    name="name"
                    value={projectForm.name}
                    error={errors.has("name")}
                    variant="standard"
                    type="text"
                    onChange={handleChange}
                />
                <TextField
                    sx={{ my: "1rem" }}
                    label="Framework"
                    variant="standard"
                    name="framework"
                    value={projectForm.framework}
                    error={errors.has("framework")}
                    type="text"
                    onChange={handleChange}
                />
                <TextField
                    sx={{ my: "1rem" }}
                    label="Langage"
                    variant="standard"
                    name="langage"
                    value={projectForm.langage}
                    error={errors.has("langage")}
                    type="text"
                    onChange={handleChange}
                />
                <TextField
                    sx={{ my: "1rem" }}
                    label="Description"
                    variant="standard"
                    name="description"
                    multiline
                    value={projectForm.description}
                    error={errors.has("description")}
                    type="text"
                    onChange={handleChange}
                />
                <Button
                    variant="outlined"
                    component="label"
                    sx={{ my: "1rem", alignSelf: "center" }}
                >
                    {selectedImage?.imageName
                        ? selectedImage.imageName
                        : "Upload Image"}
                    <input
                        type="file"
                        onChange={onImageUpload}
                        ref={imageInputRef}
                        hidden
                    />
                </Button>
                {selectedImage?.preview && (
                    <Container
                        sx={{ display: "flex", justifyContent: "center" }}
                    >
                        <img
                            src={selectedImage.preview}
                            alt="Preview"
                            style={{ height: "auto", width: "10rem" }}
                        />
                    </Container>
                )}
                <Button
                    sx={{ my: "1rem", alignSelf: "center" }}
                    type="submit"
                    variant="contained"
                >
                    Create
                </Button>
            </FormControl>
        </Container>
    );
};

export default FormPortfolio;
