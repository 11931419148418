import { createContext } from "react";

export type AppContextType = {
    authToken: string | null;
    setAuthToken: (newToken: string | null) => void;
};

export default createContext<AppContextType>({
    authToken: "",
    setAuthToken(newToken) {},
});
