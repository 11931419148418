import axios from "axios";
import { getToken } from "./AuthApi";

const AxiosClient = axios.create({
    baseURL: "https://api.otchi.ovh",
    headers: {
        Authorization: `Bearer ${getToken()}`,
    },
});

export default AxiosClient;
