import {
    Avatar,
    Divider,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
} from "@mui/material";
import { Project } from "./Project";
import React from "react";

type RowPortfolioType = {
    project: Project;
    onDrag: (e: React.DragEvent<HTMLLIElement>, index: number) => void;
    onDragEnter: (e: React.DragEvent<HTMLLIElement>, index: number) => void;
    onDrop: (e: React.DragEvent<HTMLLIElement>) => void;
    index: number;
};

const RowPortfolio = ({
    project,
    onDrag,
    onDragEnter,
    onDrop,
    index,
}: RowPortfolioType): JSX.Element => {
    return (
        <>
            <ListItemButton>
                <ListItem
                    onDragStart={(e) => {
                        onDrag(e, index);
                    }}
                    onDragEnter={(e) => {
                        onDragEnter(e, index);
                    }}
                    onDragEnd={(e) => {
                        onDrop(e);
                    }}
                    draggable
                >
                    <ListItemAvatar>
                        <Avatar
                            src={
                                "https://api.otchi.ovh/portfolio/project/img/" +
                                project.image
                            }
                        />
                    </ListItemAvatar>
                    <ListItemText
                        primary={project.name}
                        secondary={`${project.langage} | ${project.framework}`}
                    />
                </ListItem>
            </ListItemButton>
            <Divider />
        </>
    );
};

export default RowPortfolio;
