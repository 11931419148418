import { Paper } from "@mui/material";
import { ApplicationRow } from "./ApplicationRow";
import { Application } from "./Declaration";

type ApplicationListType = {
    applications: Application[];
    onUpdateApps: (apps: Application[]) => void;
};

export const ApplicationList = ({
    applications,
    onUpdateApps,
}: ApplicationListType): JSX.Element => {
    return (
        <Paper
            elevation={4}
            sx={{
                padding: 2,
                display: "flex",
                flexDirection: "column",
                gap: 2,
            }}
        >
            {applications.map((app) => (
                <ApplicationRow app={app} onUpdateApps={onUpdateApps} />
            ))}
        </Paper>
    );
};
