import AxiosClient from "./AxiosClient";

export function getToken() {
    return window.localStorage.getItem("auth_token");
}

export function setToken(newToken: string) {
    window.localStorage.setItem("auth_token", newToken);
}

export function clearToken() {
    window.localStorage.removeItem("auth_token");
}

export function isLog() {
    AxiosClient.get("auth/check-token")
        .then(() => {})
        .catch(() => {
            window.location.replace("/");
        });
}
