import { Paper } from "@mui/material";
import { useEffect, useState } from "react";
import AxiosClient from "../../Services/AxiosClient";
import { ApplicationList } from "./ApplicationList";
import { Application } from "./Declaration";

export const Applications = (): JSX.Element => {
    const [applications, setApplications] = useState<Application[]>([]);

    useEffect(() => {
        AxiosClient.get("/applications")
            .then((res) => {
                setApplications(res.data.applications);
            })
            .catch((err) => {});
    }, []);

    return (
        <Paper sx={{ display: "flex", padding: 2 }}>
            <ApplicationList
                applications={applications}
                onUpdateApps={setApplications}
            />
        </Paper>
    );
};
