import { Avatar, Icon, IconButton, Paper } from "@mui/material";
import { useCallback, useMemo } from "react";
import AxiosClient from "../../Services/AxiosClient";
import { AppStatus, Application } from "./Declaration";

type ApplicationRowType = {
    app: Application;
    onUpdateApps: (apps: Application[]) => void;
};
export const ApplicationRow = ({
    app,
    onUpdateApps,
}: ApplicationRowType): JSX.Element => {
    const icon = useMemo(() => {
        switch (app.status) {
            case AppStatus.OFFLINE:
                return "power_settings_new";
            case AppStatus.ONLINE:
                return "power_off";
            case AppStatus.PENDING:
                return "autorenew";
            default:
                return "";
        }
    }, [app.status]);

    const onChangeAppState = useCallback(() => {
        const action =
            app.status === AppStatus.OFFLINE ? "startApp" : "stopApp";
        AxiosClient.get(`/${action}/${app.id}`)
            .then(({ data }) => {
                onUpdateApps(data.apps);
            })
            .catch(() => {});
    }, [app.id, app.status, onUpdateApps]);

    return (
        <Paper
            elevation={6}
            sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: 1,
            }}
        >
            <Avatar alt={app.name} src={app.image} sx={{ marginRight: 1 }} />
            {app.name}
            {app.containerArgs !== null ? (
                <IconButton
                    disabled={app.status === AppStatus.PENDING}
                    onClick={onChangeAppState}
                    sx={{ marginLeft: 1 }}
                >
                    <Icon>{icon}</Icon>
                </IconButton>
            ) : (
                <IconButton sx={{ marginLeft: 1 }}>
                    <Icon>add</Icon>
                </IconButton>
            )}
        </Paper>
    );
};
