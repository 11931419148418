import { Button, Grid, Paper } from "@mui/material";

type ButtonData = {
    name: string;
    logo: string;
    link: string;
};

const buttons: ButtonData[] = [
    {
        name: "Portfolio",
        logo: "https://cdn.discordapp.com/attachments/823569606328320021/1115193257522122762/porte-ouverte.png",
        link: "https://portfolio.otchi.ovh",
    },
    {
        name: "Github",
        logo: "https://cdn-icons-png.flaticon.com/512/25/25231.png",
        link: "https://github.com/OtchiTan",
    },
    {
        name: "Gitlab",
        logo: "https://cdn.icon-icons.com/icons2/2415/PNG/512/gitlab_original_logo_icon_146503.png",
        link: "https://gitlab.com/OtchiTan",
    },
    {
        name: "Otchi Gitlab",
        logo: "https://gitlab.otchi.ovh/assets/logo-911de323fa0def29aaf817fca33916653fc92f3ff31647ac41d2c39bbe243edb.svg",
        link: "https://gitlab.otchi.ovh",
    },
    {
        name: "Selenia",
        logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/84/Twenty_sided_dice.svg/1780px-Twenty_sided_dice.svg.png",
        link: "https://selenia.otchi.ovh",
    },
];

const Home = (): JSX.Element => {
    return (
        <Paper sx={{ height: "100%" }}>
            <Grid
                container
                spacing={2}
                sx={{ height: "100%", width: "100%", margin: "0.5rem" }}
                item={true}
            >
                {buttons.map((button) => (
                    <Grid
                        xs={4}
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                        item={true}
                        key={button.name}
                    >
                        <img
                            src={button.logo}
                            alt={button.name}
                            style={{ width: "20%", marginBottom: "2rem" }}
                        />
                        <Button variant="outlined" href={button.link}>
                            {button.name}
                        </Button>
                    </Grid>
                ))}
            </Grid>
        </Paper>
    );
};

export default Home;
