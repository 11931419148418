import { Button, FormControl, Paper, TextField } from "@mui/material";
import { FormEvent, useContext, useState } from "react";
import AppContext from "../../AppContext";
import { setToken } from "../../Services/AuthApi";
import AxiosClient from "../../Services/AxiosClient";

type RegisterProps = {
    username: string;
    password: string;
    confirmPassword: string;
};

const Register = (): JSX.Element => {
    const [registerForm, setRegisterForm] = useState<RegisterProps>({
        username: "",
        password: "",
        confirmPassword: "",
    });
    const { setAuthToken } = useContext(AppContext);

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        AxiosClient.post("auth/register", registerForm)
            .then(({ data }) => {
                setAuthToken(data.authToken);
                setToken(data.authToken);
                window.location.replace("/dashboard");
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setRegisterForm((loginForm) => {
            return { ...loginForm, [e.target.name]: e.target.value };
        });
    };

    return (
        <Paper
            sx={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <form
                onSubmit={handleSubmit}
                style={{ display: "flex", flexDirection: "column" }}
            >
                <FormControl>
                    <TextField
                        sx={{ my: "1rem" }}
                        label="Username"
                        name="username"
                        value={registerForm.username}
                        variant="standard"
                        type="text"
                        onChange={handleChange}
                    />
                    <TextField
                        sx={{ my: "1rem" }}
                        label="Password"
                        variant="standard"
                        name="password"
                        value={registerForm.password}
                        type="password"
                        onChange={handleChange}
                    />
                    <TextField
                        sx={{ my: "1rem" }}
                        label="Confirm Password"
                        variant="standard"
                        name="confirmPassword"
                        value={registerForm.confirmPassword}
                        type="password"
                        onChange={handleChange}
                    />
                    <Button
                        sx={{ my: "1rem" }}
                        type="submit"
                        variant="contained"
                    >
                        Register
                    </Button>
                </FormControl>
            </form>
        </Paper>
    );
};

export default Register;
