import { Button, Container, Divider, List } from "@mui/material";
import { Project } from "./Project";
import RowPortfolio from "./RowPortfolio";
import { useEffect, useRef } from "react";

type ListPortfolioType = {
    projects: Project[];
    setProjects: React.Dispatch<React.SetStateAction<Project[]>>;
};

const ListPortfolio = ({
    projects,
    setProjects,
}: ListPortfolioType): JSX.Element => {
    const originDragPos = useRef<number>(-1);
    const destinationDragPos = useRef<number>(-1);

    const onDrag = (e: React.DragEvent<HTMLLIElement>, index: number) => {
        originDragPos.current = index;
    };
    const onDragEnter = (e: React.DragEvent<HTMLLIElement>, index: number) => {
        destinationDragPos.current = index;
    };
    const onDrop = (e: React.DragEvent<HTMLLIElement>) => {
        const tempProjects = projects.slice();
        const tempProject = projects[destinationDragPos.current];
        tempProjects[destinationDragPos.current] =
            tempProjects[originDragPos.current];
        tempProjects[originDragPos.current] = tempProject;
        setProjects(tempProjects);
    };

    return (
        <List
            sx={{
                m: "5rem",
                width: "50%",
                overflow: "auto",
                maxHeight: "60vh",
            }}
        >
            {projects.map((project, index) => (
                <RowPortfolio
                    index={index}
                    key={project.name}
                    onDrag={onDrag}
                    onDragEnter={onDragEnter}
                    onDrop={onDrop}
                    project={project}
                />
            ))}
        </List>
    );
};

export default ListPortfolio;
