import { Button, Grid, Paper } from "@mui/material";

type ButtonData = {
    name: string;
    logo: string;
    link: string;
};

const buttons: ButtonData[] = [
    {
        name: "Portfolio",
        logo: "https://cdn.discordapp.com/attachments/823569606328320021/1115193257522122762/porte-ouverte.png",
        link: "/portfolio",
    },
    {
        name: "Applications",
        logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/be/Circle-icons-stack.svg/2048px-Circle-icons-stack.svg.png",
        link: "/applications",
    },
];

const Dashboard = (): JSX.Element => {
    return (
        <Paper sx={{ height: "100%" }}>
            <Grid
                container
                spacing={2}
                sx={{ height: "100%", width: "100%", margin: "0.5rem" }}
                item={true}
            >
                {buttons.map((button) => (
                    <Grid
                        xs={4}
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                        item={true}
                        key={button.name}
                    >
                        <img
                            src={button.logo}
                            alt={button.name}
                            style={{ width: "20%", marginBottom: "2rem" }}
                        />
                        <Button variant="outlined" href={button.link}>
                            {button.name}
                        </Button>
                    </Grid>
                ))}
            </Grid>
        </Paper>
    );
};

export default Dashboard;
