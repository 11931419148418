import { useContext, useEffect, useState } from "react";
import AxiosClient from "../../Services/AxiosClient";
import { clearToken } from "../../Services/AuthApi";
import AppContext from "../../AppContext";

type AuthRouteType = {
    element: JSX.Element;
};

const AuthRoute = ({ element }: AuthRouteType): JSX.Element => {
    const [canShow, setCanShow] = useState<boolean>(false);
    const { setAuthToken } = useContext(AppContext);

    useEffect(() => {
        AxiosClient.get("auth/check-token")
            .then(() => {
                setCanShow(true);
            })
            .catch(() => {
                clearToken();
                setAuthToken(null);
                window.location.replace("/");
            });
    }, [setAuthToken]);

    return canShow ? element : <></>;
};

export default AuthRoute;
