import { Button, Stack } from "@mui/material";
import { useContext } from "react";
import AppContext from "../../AppContext";

const Navbar = (): JSX.Element => {
    const { authToken } = useContext(AppContext);

    return (
        <Stack
            component={"nav"}
            direction={"row"}
            justifyContent={"space-between"}
            paddingX={"2rem"}
            paddingY={"0.5rem"}
            className="Navbar"
            color={"secondary"}
            sx={{ backgroundColor: "primary.main" }}
        >
            <Button variant="text" color="secondary" href="/">
                Home
            </Button>
            {authToken && (
                <Button variant="text" color="secondary" href="/dashboard">
                    Dashboard
                </Button>
            )}
            {authToken ? (
                <Button variant="text" color="secondary" href="/logout">
                    Logout
                </Button>
            ) : (
                <Button variant="text" color="secondary" href="/login">
                    Login
                </Button>
            )}
        </Stack>
    );
};

export default Navbar;
