import { Container, Paper } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Project } from "./Project";
import AxiosClient from "../../Services/AxiosClient";
import FormPortfolio from "./FormPortfolio";
import ListPortfolio from "./ListPortfolio";

const Portfolio = (): JSX.Element => {
    const [projects, setProjects] = useState<Project[]>([]);

    const getProjects = useCallback(() => {
        AxiosClient.get("portfolio/project")
            .then((res) => {
                setProjects(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const reorderProjects = useCallback(() => {
        AxiosClient.post("portfolio/project/reorder", projects);
    }, [projects]);

    useEffect(() => {
        getProjects();
    }, [getProjects]);

    useEffect(() => {
        reorderProjects();
    }, [projects]);

    return (
        <Paper
            sx={{
                height: "100%",
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
            }}
        >
            <ListPortfolio projects={projects} setProjects={setProjects} />
            <FormPortfolio onNeedRefresh={getProjects} />
        </Paper>
    );
};

export default Portfolio;
