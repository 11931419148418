import { Button, FormControl, Paper, TextField } from "@mui/material";
import { FormEvent, useContext, useState } from "react";
import AppContext from "../../AppContext";
import { setToken } from "../../Services/AuthApi";
import AxiosClient from "../../Services/AxiosClient";

type LoginForm = {
    username: string;
    password: string;
};

const Login = (): JSX.Element => {
    const [loginForm, setLoginForm] = useState<LoginForm>({
        username: "",
        password: "",
    });
    const { setAuthToken } = useContext(AppContext);

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        AxiosClient.post("auth/login", loginForm)
            .then(({ data }) => {
                setAuthToken(data.authToken);
                setToken(data.authToken);
                window.location.replace("/dashboard");
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLoginForm((loginForm) => {
            return { ...loginForm, [e.target.name]: e.target.value };
        });
    };

    return (
        <Paper
            sx={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <form
                onSubmit={handleSubmit}
                style={{ display: "flex", flexDirection: "column" }}
            >
                <FormControl>
                    <TextField
                        sx={{ my: "1rem" }}
                        label="Username"
                        name="username"
                        value={loginForm.username}
                        variant="standard"
                        type="text"
                        onChange={handleChange}
                    />
                    <TextField
                        sx={{ my: "1rem" }}
                        label="Password"
                        variant="standard"
                        name="password"
                        value={loginForm.password}
                        type="password"
                        onChange={handleChange}
                    />
                    <Button
                        sx={{ my: "1rem" }}
                        type="submit"
                        variant="contained"
                    >
                        Login
                    </Button>
                    <Button
                        onClick={() => window.location.replace("/register")}
                    >
                        Register
                    </Button>
                </FormControl>
            </form>
        </Paper>
    );
};

export default Login;
